<template>
  <div>
    <component
      :is="print_type"
      :debit_statement="debit_statement"
      v-if="hasBills"
    />
    <span class="ma-5 text-h5" v-if="!hasBills"> Nada Consta </span>
  </div>
</template>

<script>
import common_printing from "@/components/bill-receivable/sections/DebitStatementCommonPrinting.vue";
import thermal_printing from "@/components/bill-receivable/sections/DebitStatementThermalPrinting.vue";
export default {
  components: {
    common_printing,
    thermal_printing,
  },

  data() {
    return {
      debit_statement: {
        customer: {},
        bills: [],
      },
    };
  },

  mounted() {
    this.$loading.start();
    this.$http
      .index("bill-receivable/customer-debit-statement", {
        customer_id: this.customer_id,
        bill_receivables_ids: this.bill_receivables_ids,
      })
      .then((response) => {
        this.debit_statement = response;
        this.$loading.finish();
      })
      .catch((error) => {
        this.loading.finish();
      });
  },

  computed: {
    print_type() {
      return this.$store.getters["app/getTypePrint"];
    },
    hasBills() {
      return this.debit_statement.bills.length;
    },

    customer_id() {
      return this.$route.params.customer_id;
    },

    bill_receivables_ids() {
      return this.$route.params.bill_receivables_ids.split(",");
    },
  },
};
</script>

<style scope>
</style>

